import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Projects from '../components/projects'

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    return (
      <Layout>
        <Helmet title="Sydney Pool Renovations" />
        <section id="promo">
          <div className="container">
            <h1>Helping families to design their dream outdoor space.</h1>
            <Link className="btn" to="/contact/">
              Ask a Question
            </Link>
          </div>
        </section>
        <section id="projects">
          <div className="container">
            <h2>Have a look at some of our recent work.</h2>
            <Projects items={posts} />
            <Link className="btn btn-blue" to="/projects/">
              View all projects
            </Link>
          </div>
        </section>
      </Layout>
    )
  }
}

/* eslint no-undef: 'off' */
export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      filter: { id: { regex: "/projects/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 2
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
