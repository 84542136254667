import React from 'react'
import Link from 'gatsby-link'

import * as styles from './project.module.css'

export default class Projects extends React.Component {
  render () {
    const { limit } = this.props
    let { items } = this.props
    if (limit) {
      items = items.slice(0, limit)
    }
    // <a key={i} href={object.path} className="project-item">
    return (
      <div className={styles.project}>
        {items.map(({ node: post }) => {
          return (
            <Link to={post.fields.slug} key={post.id} className={styles.item}>
              <figure>
                <img src={post.frontmatter.image} alt={post.frontmatter.title} />
                <figcaption>
                  <h2>{post.frontmatter.title}</h2>
                  {/* <p>{post.excerpt}</p> */}
                </figcaption>
              </figure>
            </Link>
          )
        })}
      </div>
    )
  }
};
